/* Common */
p,h1,h2,h3,h4,h5,h6,label {
  font-family: "Quicksand", Sans-serif !important;
}

.error-label{
  color: #F86C6B !important;
  font-size: 1rem;
  text-align: center;
  font-weight: bold;
}

.update-title-style{
  font-size:  2rem;
  font-weight: bold;
  align-self: center
}

.update-input-div{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.update-form-style{
  width: 85% !important;
}

.form-control{
  border-radius: 15px !important;
  margin: 5px 0px;
  width: 100%;
}
.header.row {
  background: #ae498c;
  padding: 10px;
  width: 100%;
    margin: 0;
}

.header .col-lg-8 {
  padding: 0px 20px;
}
nav.navbar.navbar-expand-lg.navbar-light.header-background.col-lg-4 {
  justify-content: flex-end;
  /* width: 40%; */
  text-align: right;
}

.align-center{
  text-align: center;
  margin: 35px 0;
}
.user-card {
  width: 40% !important;
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.box-shadow-class{
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  border-radius: 15px;
  text-overflow:ellipsis;
  overflow: hidden;
}
.test-score{
  margin-top : 15px;
  display : flex;
  flex-direction: row;
  justify-content: space-between;
}
.collapse-button-div{
  align-self: center;
  text-align: center; 
  margin: auto;
  border-radius: 10px;
  background-color: #AE498C;
  padding: 10px;
}
.transcript-div.box-shadow-class svg {
  fill: white;
}
.prev-next-div button {
  line-height: 1;
}
.test-view {
  padding: 10px 20px;
}

.content-label-style{
  color: #000000;
  font-size: 1.1rem;
}

.seach-data-label-style{
  color: #000000;
  font-size: 1.1rem;
  font-style: italic;
}

.header-label-style{
  color : #ffffff !important;
  font-size:  1rem;
}

.prev-next-label{
  font-size:  1rem;
}

label{
  margin-bottom: 0rem !important
}

.sub-label-style{
  font-size:  1rem;
}

.button-label{
  margin : .6rem;
  cursor: pointer;
  color : #FFFFFF;
  font-size: .85rem;
}

/* Login */

.login-container{
  width: 80%;
  margin: auto;
}

.login-label-style{
  color: #AE498C !important;
  font-size:  1rem;
  font-weight: bold;
  align-self: center
}

.submit-button{
  border-radius: 15px;
  background-color: #AE498C;
  border-color: #AE498C;
  font-size:  1rem !important;
}

.resume-icon{
  height: 20px;
    width: 20px;
    align-self: center;
}

.fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

/* Home */

.container {
  margin : 4% 10% !important;
  max-width: 85%;
  min-height : 100vh;
}

.scroll-container{
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.list-group{
  display: flex;
  flex-direction: row;
}

.list-group-item{
  padding: .5rem;
  margin: 0.5rem 1rem 1.5rem 1rem;
  border: none;
}

.no-data-div {
  width: 100%;
  height: calc(100vh - 50vh);
  display: grid;
  place-items: center;
}

.loader-main-div {
  width: 100%;
  height: 50vh;
  display : flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.loader-center-div {
  width: 50%;
  height: calc(50vh - 25vh);
  display: grid;
  place-items: center;
}

.loader-div{
  width: 10rem;
    height: 10rem;
    background-color: transparent;
    border: 1rem solid ghostwhite;
    border-radius: 50%;
    border-top: 1rem solid #AE498C;
    animation: loaders 2s linear infinite;
}

.grade-card{
  width: 45% !important;
  margin: 10px auto;
}

.grade-details{
  display: flex;
  justify-content: space-around;
}
@keyframes loaders{
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Offer */

.rupee-label-style{
  color: #AE498C !important;
  font-size: 1.6rem;
  margin-left: 2px;
}

p{
  font-size: 1rem;
}
.center-class{
  margin-top: 20%;
  text-align: center;
  width: 100%;
}

.align-center{
  text-align: center;
  margin: 15px;
}

.logo-acusmart{
  height: 50%;
  width: 70%;
  padding: 0.8rem;
  object-fit: scale-down;
}

.update-header-style{
  color: #AE498C !important;
  font-size:  2rem;
  font-weight: bold;
  align-self: center
}

.rs-play, .rs-stop, .rs-pause, .rs-resume{
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  color: #ffffff !important;
  background-color : #AE498C !important;
}

.play-button{
  margin-left: 10px;
  text-align: center;
  width: 35px;
  height: 35px;
  border-radius: 50% !important;
  color: #ffffff !important;
  background-color : #AE498C !important;
  border: none;
}

.play-button:hover{
  box-shadow: 6px 7px 19px #58585899;
    transform: scale(1.03);
    transition: all .3s;
}

a.header-active-class{
  background-origin: 10px solid red;
}

.header-nav-segment{
  align-self: flex-end;
}

.logo-icon{
  height: 45px;
  width: 145px;
  object-fit: scale-down;
}

.icon-style{
  color : #ffffff;
  padding : 3px;
  font-size:  1rem;
}

.search-icon-style{
  align-self : center;
  padding : 0px 5px;
  font-size:  1.5rem;
  color: #AE498C;
}

.flex-end{
  display: flex;
    justify-content: flex-end;
    margin: 15px;
}

.page-component{
  height: 115px !important;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  padding: 0.8rem 1.2rem;
}

.page-data{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px !important;
}

.search-data{
  width: 90%;
  margin: auto;
  margin-top: 10px;
}

.rupee-icon-style{
  color: #AE498C !important;
  font-size: 1.5rem;
}

.title-label-style {
  color: #000000;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
}

.combo-label-style {
  color: #000000;
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}

.content-label-style{
  color: #000000;
  font-size: 1.1rem;
}

.seach-data-label-style{
  color: #000000;
  font-size: 1.1rem;
  font-style: italic;
}

label{
  margin-bottom: 0rem !important
}

.continue-reading-card{
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  width: 100%;
}

.continue-reading-section{
  padding: 1%;
  height: 100px;
  width: 30%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  text-align: center;
  height: 115px !important;
}

.chapter-list-card, .transcript-div{
  cursor: pointer;
  height: 70px;
  margin-top: 1rem;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.transcript-audio-div{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* height: 550px; */
  margin-top: 1rem;
  padding: 1.2rem;
  align-items: flex-start;
  width: 100%;
}

.header-tag{
  display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.transcript-media-div{
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 550px;
  margin-top: 1rem;
  padding: 1.2rem;
  align-items: flex-start;
  width: 100%;
}

.content-data{
  overflow-y: auto;
  width: -webkit-fill-available;
}

.content-info{
  display:flex !important; 
  flex-direction:column;
  margin-bottom: 20px;
}

.audio-content-info{
  display:flex !important; 
  flex-direction:row;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
}

.progress-card{
  align-items: center;
  margin-top: 2rem;
  padding: 1rem;
  height: 115px !important;
}

.read-entire-book{
  align-items: center;
  margin: 2rem 0rem;
  padding: 0.6rem;
  display: flex;
  flex-direction: row;
  height: 115px !important;
}

.reader-icon{
  font-size: 1.6rem;
  width: 40px;
  height: 40px;
  padding: 5px;
}

.pdf-border{
  border : 1px solid black
}

.book-component{
  padding : .5rem;
  margin: 0.5rem 1rem 1.5rem 1rem;
}

.book-component .box-shadow-class {
  box-shadow: 0 0 5px #00000080;
}

.book-component:hover .box-shadow-class {
    box-shadow: 6px 7px 19px #58585899;
    transform: scale(1.03);
    transition: all .3s;
}

.search-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.book-image{
  width: 212px;
  height: 300px;
  object-fit: scale-down;
}

.additional-image{
  width: 90%;
  height: 380px;
  object-fit: contain;
}

.form-control{
  border-radius: 15px !important;
  margin: 5px 0px;
  width: 100%;
}

.update-form-style{
  width: 85%;
}

.update-input-div{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.user-input-div{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.user-input-details{
  margin-right: 15px;
}

.input-page{
  border-radius: 15px !important;
  border: 1px solid black;
  height: 35px;
  width: 100px;
  padding: 15px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #AE498C;
  border-color: #AE498C;
}

.header-background{
  background-color: #AE498C;
  justify-content: space-between;
}

.card-left{
  margin : 0 10px;
  text-align:center !important
}

.login-container{
    width: 100%;
    padding-right: 15%;
    padding-left: 15%;
    margin-right: auto;
    margin-left: auto;
}

.user-card {
  width: 40% !important;
  margin: auto;
  padding: 1rem;
  display: flex;
    flex-direction: column;
}

.card-right{
  width : 80% !important;
}

.row-click{
  cursor: pointer;
}

.offer-title{
  text-align: center;
}

.custom-card{
  margin: 1rem;
  max-height: 100px;
}

.custom-card-body{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.grade-div-combo{
  text-align: center; 
  margin: auto;
  width: 100px;
  border-radius: 10px;
  background-color: #AE498C;
  padding: 10px;
}

.offer-card {
  height: 160px;
  width: 300px;
  padding: .5rem;
  margin: 0.5rem 1rem 1.5rem 1rem;
}

.offer-card:hover, .continue-reading-section:hover, .read-entire-book:hover, .chapter-list-card:hover, .page-component:hover, .test-score:hover, .badge-primary:hover  {
    box-shadow: 6px 7px 19px #58585899;
    transform: scale(1.03);
    transition: all .3s;
}

.offer-books {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
}

.book-div-combo{
  text-align: center; 
  margin: auto;
  width: 100px;
  border-radius: 10px;
  background-color: #298eab;
  padding: 10px;
}

input[type = radio]{
  margin-right: 5px;
}

input[type=search] {
  width: 70%;
  box-sizing: border-box;
  border: 2px solid #AE498C;
  font-size: 1rem;
  padding: 12px;
  border-radius: 4px !important;
  outline-offset : 0px !important;
}

.book-container { 
  margin : 0 2%;
  display: flex;
  flex-direction: row;
}

.read-book-container {
  width: 95%;
  display: flex;
  flex-direction: row;
}

.pdf-div {
  width: 40%;
  justify-content: center;
  display: grid;
}

.pdf-control-div{
  width: 60%;
}

.prev-next-div{
  margin: 1rem;
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.prev-next{
  cursor: pointer;
  font-size:  1rem;
}

.block-icon{
  height: 35px;
  width: 35px;
  align-self:center
}

.read-entire-icon{
  height: 60px;
  width: 60px;
  align-self:center
}

.react-pdf__Page{
  height: 610px !important;
}
.book-detail-image {
  width: 25%;
  float: left;
  text-align: center;
}
.question-view {
  margin: 5px 0px;
}
.question-image-view{
  text-align: center;
}
.question-image {
  width: 180px;
  height: auto;
  border: 1px solid black;
  margin: 10px 0;
}

.book-detail-data {
  width: 75%;
  float: left;
  padding: 0.6rem;
  min-height: 400px;
}

.react-pdf__Page__canvas, .react-pdf__Document{
  width: 396px !important;
  height: 612px !important;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background:#AE498C;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}
::-webkit-scrollbar-thumb:window-inactive {
background: #AE498C; 
}

@media(max-width : 1024px){
  .navbar{
    padding: 0.5rem;
  }
  .container {
    margin: 4% 2% !important;
    min-height : 90vh !important;
    max-width: 95% !important;
  }
  .logo-icon{
    height: 26px;
    width: 80px;
  }
  .card-right{
    text-align: center;
  }
  .react-pdf__Page__canvas, .react-pdf__Document{
    width: 396px !important;
    height: 612px !important;
  }
  .book-image{
    width: 100px;
    height: 150px;
  }

  .user-card {
    width: 80% !important;
    padding: 0.6rem;
  }

  .combo-label-style{
    font-size : 12px ;
  }

  .rupee-label-style,.rupee-icon-style{
    font-size : 15px ;
  }

  .grade-div-combo, .book-div-combo{
    margin: 5px;
    width: 85px;
  }

  .book-detail-data{
    width: 100%;
    text-align: center;
    padding: 5px;
    float: none;
  }

  .book-detail-image{
    width: 100%;
    text-align: center;
  }

  .continue-reading-section{
    height: 90px;
  }

  .book-container{
    flex-direction: column;
  }

  .read-book-container {
    width: 100%;
    flex-direction: column;
  }

  .pdf-div{
    width : 100%
  }

  .react-pdf__Page{
    height: 500px !important;
  }
  
  .react-pdf__Page__canvas{
    width: 330px !important;
    height: 500px !important;
  }

  .chapter-list-card, .read-entire-book, .progress-card,.transcript-div{
    height: auto !important;
  }

  .prev-next-div{
    width: 95%;
    margin: 3%;
    text-align: center;
  }

  .pdf-control-div{
    margin-top: 1rem !important;
    width: 100% !important;
  }

  .update-input-div{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .update-form-style{
    width: 100%;
  }

  .rs-container{
    display: flex;
    flex-direction: row;
  }
}

@media(max-width : 768px){

  .navbar{
    padding: 0.5rem;
  }
  .header.row {
    flex-wrap: nowrap;
    padding: 0 20px;
}
ul.navbar-nav.mr-auto {
  flex-direction: row;
}
li.nav-item {
  margin: 0 10px;
}
.react-pdf__Page__canvas, .react-pdf__Document{
  width: 350px !important;
  height: 500px !important;
}
  .container {
    margin: 4% 2% !important;
    min-height : 90vh !important;
    max-width: 95% !important;
  }

  .logo-icon{
    height: auto;
    width: 120px;
  }
  .header .col-lg-8 {
    padding: 0;
}
/*
  .book-component{
     width: 37%; 
  }
*/
  
  .book-image{
    width: 100px;
    height: 150px;
  }

  .book-detail-data{
    width: 100%;
    text-align: center;
    padding: 5px;
    float: none;
  }

  .book-detail-image{
    width: 100%;
    text-align: center;
  }

  .continue-reading-section{
    height: 90px;
  }

  .book-container{
    width: 90%;
    flex-direction: column;
  }

  .read-book-container {
    width: 95%;
    flex-direction: column;
  }

  .pdf-div{
    width : 100%
  }
  
  .react-pdf__Page{
    margin: auto;
    height: 450px !important;
    width: fit-content;
  }

  .react-pdf__Page__canvas{
    width: 300px !important;
    height: 450px !important;
  }

  .prev-next-div{
    width: 95%;
    margin: 3%;
    text-align: center;
  }

  .pdf-control-div{
    margin-top: 1rem !important;
    width: 100% !important;
  }
}